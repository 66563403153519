import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";

import SEO from "../../components/seo";
import {
  ohioHero,
  ohioSeal,
  ohioRestaurantTypes,
  ohioTestimonialsData,
  ohioIconTemplate,
  ohioLargeFeatures,
  ohioXL,
  ohioWhiteGlove,
} from "../../data/local/ohio-data";
// import LargeFeatures from "../../components/LargeFeatures/LargeFeatures";
// import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
// import LocalRestaurants from "../../components/ColoradoPortedComps/LocalRest";
// import Restaurants from "../../components/ColoradoPortedComps/Rest";
// import PartnerLogos from "../../components/ColoradoPortedComps/PartnerLogos";
// import RASeal from "../../components/ColoradoPortedComps/RASeal";
// import XLArea from "../../components/ColoradoPortedComps/XLArea";
// import LocalSlider from "../../components/ColoradoPortedComps/LocalSlider";

import ogImage from "../../images/global_assets/og-image.png";
import LocalHero from "../../components/Hero/LocalHero";

const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LocalRestaurants = loadable(() =>
  import("../../components/ColoradoPortedComps/LocalRest")
);
const Restaurants = loadable(() =>
  import("../../components/ColoradoPortedComps/Rest")
);
const PartnerLogos = loadable(() =>
  import("../../components-v2/Sections/PartnerLogos")
);
const XLArea = loadable(() =>
  import("../../components/ColoradoPortedComps/XLArea")
);
const LocalSlider = loadable(() =>
  import("../../components/ColoradoPortedComps/LocalSlider")
);

const whiteGloveImport = "caregiver.png";

const ohioRa = () => {
  return (
    <Layout
      isLanding
      secondaryNav
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="SpotOn | Ohio Restaurant Association"
        description="SpotOn is empowering Ohio restaurants and small businesses with integrated point-of-sale systems and software, backed by in-person local support and fair pricing."
        image={`https://spoton.com/${ogImage}`}
      />
      <LocalHero sectionData={ohioHero} seal={ohioSeal} />
      <PartnerLogos />
      <LocalRestaurants data={ohioRestaurantTypes} />
      <Restaurants data={ohioIconTemplate} nav="pos" />
      <LocalSlider nav="cust" testimonialsData={ohioTestimonialsData} />
      <LargeFeatures
        sectionData={ohioLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <XLArea
        data={ohioXL}
        nav="service"
        phoneNumber="+18882246506"
        phoneText="(888) 224-6506"
      />
      <WhiteGlove
        sectionData={ohioWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
    </Layout>
  );
};

export default ohioRa;
